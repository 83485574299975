'use client';

import React from 'react';
import { MdArrowBackIos } from 'react-icons/md';

import {
  Button,
  ButtonGroup,
  Center,
  Heading,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useRouter } from 'next/navigation';

export default function NotFoundPage() {
  const router = useRouter();

  return (
    <Center as="main" h="100vh">
      <VStack>
        <Heading as="h1" size="4xl">
          404
        </Heading>
        <Text>Pagina não encontrada!</Text>
        <ButtonGroup>
          <Button
            onClick={() => router.back()}
            leftIcon={<Icon as={MdArrowBackIos} />}
          >
            Voltar
          </Button>
        </ButtonGroup>
      </VStack>
    </Center>
  );
}
